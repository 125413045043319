export default {
  mainContainer: 'overflow-hidden',
  container: 'flex md:-translate-x-0 ease-in-out duration-300',
  showSideBar: '-translate-x-0',
  hideSideBar: '-translate-x-[235px]',
  sideBar: 'w-[236px] px-[20px] bg-[#F2F5F9] border-r border-[#CCDCE8] fixed',
  logoWrapper: 'hidden md:inline-block pt-[24px]',
  logo: 'h-[60px]',
  linksWrapper: 'flex flex-col mt-[85px]',
  link: 'flex items-center py-[10px] px-[8px] mb-[15px] rounded-lg gap-[15px] hover:opacity-80',
  activeLink: 'bg-color-white text-[#00518B]',
  inactiveLink: 'text-[#404653]',
  linkText: 'font-interSemibold text-[16px]',
  count: 'w-[24px] h-[19px] flex items-center justify-center ml-auto text-[12px] font-inter bg-[#E6EEF3] rounded-full',
  mobileHeader: 'md:hidden w-full p-4 flex items-center justify-between border-b border-[#CEDCEC]',
  userDropdown: 'md:hidden mt-[20px]',
  child: 'w-screen ml-[236px] p-2',
  hidden: 'hidden',
  cursor: 'cursor-pointer',
};
